.App {
  display: flex;
  flex-direction: column;
  vertical-align: center;
  justify-content: center;
  text-align: center;
  background-color: #282c34;
  color: white;
  min-height: 100vh;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-delay: 0s;
}

.App.failed {
  background-color: #755;
  transition-duration: 0s;
}

.quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.question {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

footer {
  flex-shrink: 0;
  color: #999;
}

footer a {
  color: #999;
}

footer span {
  padding: 0.4em;
}

h1 {
  font-size: 2.2em;
}

.scores {
  min-height: 3em;
  font-size: 1.2em;
}

.Answer-link {
  font-size: 1.4em;
  padding: 0.2em;
  margin: 0.2em;
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 50em) {
  .mobile-break {
    display: block;
    height: 0;
  }
}


a, button {
  color: white;
}

a:focus, button:focus, a:active, button:active {
  color: #ff6;
}

@media (hover) {
  a:hover, button:hover {
    color: #ff6;
  }
}

.score, .high-score-current {
  color: #8c4;
}

.score.score-zero, .score.score-failed {
  color: #c88;
}

.wrong-answer .incorrect {
  color: #c88;
  text-decoration: line-through;
}
